import React from 'react'
import { Flex, Heading, Text, Image } from 'components'

export const SuccessPage = ({ heading, text }) => (
  <Flex col hAlignContent="center" vAlignContent="center" grow>
    <Flex row wrap box boxShadow="small" m={[16]} p={[16]} bg="offWhite">
      <Flex col center mx={[0, null, 4]}>
        <Heading.h1 textAlign="center" fontSize="2xl" letterSpacing="wide">
          Thank You
        </Heading.h1>
        <Heading.h2 textAlign="center">Your message has been sent.</Heading.h2>
        <Text textAlign="center">
          A member of our team will be in contact with you shortly.
        </Text>
      </Flex>
    </Flex>
  </Flex>
)

export default SuccessPage
